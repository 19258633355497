import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Camera Login with 2-Factor Authentication",
  "path": "/Frequently_Asked_Question/WQHD_2fa_Authentication/",
  "dateChanged": "2023-08-15",
  "author": "Mike Polinowski",
  "excerpt": "How do I set up a 2fa authentication and password recovery for my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Camera Login with 2-Factor Authentication' dateChanged='2023-08-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='How do I set up a 2fa authentication and password recovery for my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_2fa_Authentication/' locationFR='/fr/Frequently_Asked_Question/WQHD_2fa_Authentication/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "camera-login-with-2-factor-authentication",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#camera-login-with-2-factor-authentication",
        "aria-label": "camera login with 2 factor authentication permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Login with 2-Factor Authentication`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: How do I set up a 2fa authentication and password recovery for my camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/User/"
      }}>{`User Management Menu`}</a>{` and click on the pencil icon next to the user you want to edit.`}</p>
    <h2 {...{
      "id": "basic-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-setup",
        "aria-label": "basic setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Setup`}</h2>
    {/* /Web_User_Interface/1440p_Serie/System/Benutzer/ */}
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/ae953/2fa_Authentication_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABN0lEQVQY00XQS0+DQBiF4e6MrXUhZWaAD8bUprWABYaBUoRyGy42MbEx3uKiarpy5f9fmdSYJmfxbp7N6RmUgq6rAIqqKHRCWYvMHFsFMnN5fhy2CsruhrJumiZjviSNDIP2VNBUABUAY2xM7Wj7fdPtnc3ear5m1W5W7q6rj1m1s+rP5PVHpvOrMSwjD4CMpNERI0LAMBj3/YD5gc9DHoQ8ipeMMx5yzvm6TRCRg8AWbWBZdHB60lMBNF1XNA0RrFO6rupMNJlo/6NJS5GWIhNNUXcIoeeXh7f3rcemw7P+AQMQVUUEA73M67Zsu7LpMlEXTbcuRVJUSVGlRSW6DSakyOPHp/s4Zud/+PCWhgjRDMNcuHPbtT3fdjxr4diu5y+jcHW7SlLLcSXpYqrjyWy8ivhw0P8FMlhAcqxyuNcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/e4706/2fa_Authentication_01.avif 230w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/d1af7/2fa_Authentication_01.avif 460w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/7f308/2fa_Authentication_01.avif 920w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/2c8e5/2fa_Authentication_01.avif 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/a0b58/2fa_Authentication_01.webp 230w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/bc10c/2fa_Authentication_01.webp 460w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/966d8/2fa_Authentication_01.webp 920w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/cc785/2fa_Authentication_01.webp 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/81c8e/2fa_Authentication_01.png 230w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/08a84/2fa_Authentication_01.png 460w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/c0255/2fa_Authentication_01.png 920w", "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/ae953/2fa_Authentication_01.png 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ebbd3e0b1ed9a963cb9e3154c1b9b0be/c0255/2fa_Authentication_01.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you try to edit the administrator account you will be asked to provide the admin login:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e03da1d7d59facd7123e80c2bd08229/d56e1/2fa_Authentication_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABmklEQVQozz3QW2/aMBTA8ah7YBTCQBDbx/ekDrZDQhKaQKuqKtMu0gqs09RdHybt+3+JLYAq/V9/Puc4UFozzpmQAISZ3Nw9i+ZAVweod1DvT5FqJ9sne/89jERdVTebW4SQkDJgQlDOqRAYI2Zye/9Nb55kexDNQbQ70Tye0pu9f3geR3I+V01bcIYnk3HAGKeMA2MYAGHEGdGaS0m1ZFohradanaN0POz3t29v3n1ovOODfi/gx8lnDDDP8rpdL1fNctUU9crl5bEiW1ZJ6t6Mwj9/f/38/aWsTDi8DCgXlHFCaYcJSb0vr5t5tnB5Yb0j3Tp4RkgEVMZJGA6329v954+bdRkO+t3NwM8YA1xZ64ulzQu3WNjVnX/44dr3n1L31S6yq3Q0GjqJU2vW1/VocBkQSpkQL1jGcWxMYoxOEhEblXqZmDSOnTFcKjqdZIlMM5dn/nWv12Eh5WnzlydOIYwjYDOmMWEKIRohCQgIdpl1qXl1cdFhFWt6/LCzIV2YAAaCeIK05yBchGKElKKz6bgsM8D4P/4H769ISJRQSVkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e03da1d7d59facd7123e80c2bd08229/e4706/2fa_Authentication_02.avif 230w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/d1af7/2fa_Authentication_02.avif 460w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/7f308/2fa_Authentication_02.avif 920w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/b9baf/2fa_Authentication_02.avif 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e03da1d7d59facd7123e80c2bd08229/a0b58/2fa_Authentication_02.webp 230w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/bc10c/2fa_Authentication_02.webp 460w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/966d8/2fa_Authentication_02.webp 920w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/bbe5b/2fa_Authentication_02.webp 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e03da1d7d59facd7123e80c2bd08229/81c8e/2fa_Authentication_02.png 230w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/08a84/2fa_Authentication_02.png 460w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/c0255/2fa_Authentication_02.png 920w", "/en/static/7e03da1d7d59facd7123e80c2bd08229/d56e1/2fa_Authentication_02.png 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e03da1d7d59facd7123e80c2bd08229/c0255/2fa_Authentication_02.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the email address that you want to set as your second factor authentication and toggle 2FA to ON:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/5f6dd/2fa_Authentication_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABuElEQVQozz3Q3WvaUADG4YBgN+vq1HpMzndOTGJMcs7Jt540tmBb9lEpZYxRRu3Yxa72/1+PubXwu3144bVcT2BCMaG2Y9OlDm9/8e2BXBxw+4TMEzLPyDzDzUFc/ZSff48ALfJMSgkhRAhZEBOIkIPQbA6YH5W7L/rqPunu0m6vLj+k3e5fcrurrvej8TxO3LqJKQbD06GFKYUYQ4yBbVPOqqYq6ypVUme6WfM8nyg9Vnqi9JnSaDKe7O+vbz+ufR8OB4P/2IboiHlt2sq0WdWoogxXS8IppgQzgigWfmDb4PDj8fH7w2rFz94NLUQJxOgVN+1FXjexznRRLoIQ2M50BqZgPp6eU84BmFVFsrvZ5joeng4sRAjE2EEvyxtTm7ao17UxqdbLOAlWcRBF4TIK0gKB85DaXuDlKh28eWs5CCFCXnHTtptua7ptY1qZFzLTUitVlmpjZGM8ZAecRipdBouT/okFMSb07+Fzx2FCZFWtX8qqSnWf0t23tr78KvWNzDgj4/ejNIkYxv1+/4gZc44YMyb8kLmCCcFcj7uCBZLHdeCFBaZ64UcrT7gwz5LJaNTr9f4AN1RUJ3omK44AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdb28566d277aa61c0ffb45bfe4d0962/e4706/2fa_Authentication_03.avif 230w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/d1af7/2fa_Authentication_03.avif 460w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/7f308/2fa_Authentication_03.avif 920w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/122bb/2fa_Authentication_03.avif 1139w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bdb28566d277aa61c0ffb45bfe4d0962/a0b58/2fa_Authentication_03.webp 230w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/bc10c/2fa_Authentication_03.webp 460w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/966d8/2fa_Authentication_03.webp 920w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/7d864/2fa_Authentication_03.webp 1139w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdb28566d277aa61c0ffb45bfe4d0962/81c8e/2fa_Authentication_03.png 230w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/08a84/2fa_Authentication_03.png 460w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/c0255/2fa_Authentication_03.png 920w", "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/5f6dd/2fa_Authentication_03.png 1139w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bdb28566d277aa61c0ffb45bfe4d0962/c0255/2fa_Authentication_03.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Optional`}</strong>{`: To further improve security you can set a security question and answer. Your camera will prompt you with this question when you attempt to recover your password:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/05fb0/2fa_Authentication_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABnElEQVQoz22P2W7TUBRFLSpRp8HBGXyncwfP8xAPaeJIDKVtSpCgqkqoeEEIxP//Akpc8YS03o7W3vtoyrFBCBCCUMKDQnWfabUj5Q4Xt7jYDaBiB/VHd/3FmONVt0qSDGPChdBACMY54xxhpNI2vfnpvflub59k/01tD7I/yP6r2Dx6b5/K/S9jSooiqOuMMzw1ZxoDYAAUAFOKsMUBK8k4YMmJLRdSmOqE5K8pmYx1/cP15uq6CXw60nWNn5oJO8qY0Dgvu37brtftetNcbqpuVdTtgO2Fs+nk958fj4dPcSKNV+Pn2YSxQQ6TdNl2aVkFSaIcWynJgM0ps7gQjmtOJnd3V/cP+7qKjfGFBqeHh9mDnC2XcV6Eaernjde8c5J6ZTtb1/dczzTGkaJRGnZNdTEaaRQA/jVT6viBHydHosgvL6N+H1X9+zC6STLbdtjcTF0Z5mkWh7quH2UuJQUgjBFGh4hnCEKEIlDW8YwZxopiQlBWZI6U5y/PNca5VOr/MiVIhpaTS8Jzy3IxFoIuZmZdZXixOHtx9hdIjUka+FldFAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c26a102ca3e46844a623fcbb50bf2fa/e4706/2fa_Authentication_04.avif 230w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/d1af7/2fa_Authentication_04.avif 460w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/7f308/2fa_Authentication_04.avif 920w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/73433/2fa_Authentication_04.avif 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c26a102ca3e46844a623fcbb50bf2fa/a0b58/2fa_Authentication_04.webp 230w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/bc10c/2fa_Authentication_04.webp 460w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/966d8/2fa_Authentication_04.webp 920w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/22e6c/2fa_Authentication_04.webp 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c26a102ca3e46844a623fcbb50bf2fa/81c8e/2fa_Authentication_04.png 230w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/08a84/2fa_Authentication_04.png 460w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/c0255/2fa_Authentication_04.png 920w", "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/05fb0/2fa_Authentication_04.png 1138w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c26a102ca3e46844a623fcbb50bf2fa/c0255/2fa_Authentication_04.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once everything is set click on submit to save your settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/8802b/2fa_Authentication_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABtklEQVQoz0XQS2/aQBSGYS9CZRqoEODLzBlfBo+xDbZnfL9hSCmJIgU1i6TLkEVSKf//F1SQqpWe7avv6Eg2pcQwiGnpSLPCxrv7MPsT2Zyge8HtCbpX6F5xe3L2v9f3H+MZqqvK9wOEEBAiIQAMgAAUVXVWorh9FjePYX+M+iPf3Yebwyexu832x/G3GeduXqwIKOPRSAJCMCEYQNF16iyKMs/yLBJxIuKqNJJ0JpLpxSSKiDKb/3w87A+5y/DX4VACwwBCdIwVTaOMVZu+aLukqHiWe4FnWARMOLOAMhcAvb2/PP16CAJ7dH0t4cuyDvA37jYiz0ORiDRbMKYhPFe1uarNFNWiC01Vmjr5cbdNeHBe/ozR/7ir2jar6rJp13HsrdbMD1zPd73ADQWo0yXRlsGSh6uhLJ8fRgzjX9z0fdtv2+2ubLs4zSMhRCrSuo6bJiobB3SXmqHgzLZkWZYwAWKaCEBDaOG6SVGKvOB5IS745iH8/tyn7VMkbiJhmzCdTngcGhgNBl/OZxuWqWOsIWRS6ng+ZYwy98xhdpDZUbNmfmnaied5AaUUJ3w9GY+vrgZ/AB6kVDXV5OthAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/e4706/2fa_Authentication_05.avif 230w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/d1af7/2fa_Authentication_05.avif 460w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/7f308/2fa_Authentication_05.avif 920w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/d5bb4/2fa_Authentication_05.avif 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/a0b58/2fa_Authentication_05.webp 230w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/bc10c/2fa_Authentication_05.webp 460w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/966d8/2fa_Authentication_05.webp 920w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/5375b/2fa_Authentication_05.webp 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/81c8e/2fa_Authentication_05.png 230w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/08a84/2fa_Authentication_05.png 460w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/c0255/2fa_Authentication_05.png 920w", "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/8802b/2fa_Authentication_05.png 1135w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ceceaf85c50e3cd205ecaf3b36d1b39e/c0255/2fa_Authentication_05.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* __Zwei-Faktor-Authentifizierung (2FA)__
     ```bash
     Sehr geehrte(r) Kunde(in),
     um die Einstellung für die 2FA-Authentifizierung zu übernehmen, verwenden Sie bitte den folgenden Code: pgKIFQ
     Dieser ist für 1 Stunde gültig.
     Ihr INSTAR Team
     ``` */}
    <p>{`Now an input form will pop-up to verify the email address that you set. You will receive an email `}<strong parentName="p">{`Two-Factor Authentication (2FA)`}</strong>{` that contains a 6-letter code, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aK3TfB`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Dear Customer,

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` order to apply the changes to your login settings, it is required to confirm with the following code: aK3TfB

This code is valid `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hour.

Your INSTAR Team`}</code></pre></div>
    <p>{`Use this code to verify your email address by adding it to input form shown below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0facd5300bf9ac6e45613b3250800c8c/7bf07/2fa_Authentication_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACA0lEQVQoz02R22/SUBzHy8uMGodExKyYaAenPee0gCtUWigwCghMKLeQgZNxVzaVOG6akJhxeeFlF96Yw2Qv/p0GuqnffF4/vyvBcRzGmGVZyACHqMj7A3f2RMp1xOyJe0VHQ8p1fPt92i5KbrckeUjSTFEUwa6DWY4BFpeSVvs30fbV3tefkS9XyqeFRvB4EW0vst9uKJvHvGWiqG29Xm80Gu9kjBHCDA3sLHS+4ngHyztYBwdtCHAI2BBgIUC05eGD+wRB6AhCp9Pd29j4X0YMhNFoLJvLJZOqmkqp6ppUKpFMptOZUDhMkiQAVoPhsVeWQ2HlVkZ3kWW51+svl7/m8/nF+fnlKhfXy+tutytJUjT+ptqsil7xBUXl3xWJv20RQhBCJRgsvy8efWzWarVqrV5rfKjVG61G87BYDPj8oXi40T763OtgzHJ2O6GdGq8LWGh4kFJ+f4+dHUf2ouF4vq62L5OH/R9K5CyWSPkD+dLB6WRyOhq1Wq3NR5sEQuhWxpiB0Mnv+D2CVxQEl8slvBY8uy5P0CeIYUHgWa5SqUwn0+FwOBqPBUFcydqrtbEBANsWqxXQANAMQyNehp6EHe1EXlI+wNSbzdlsNp5Ox4NB/q36r7O2NkmSBoNhy/zcZDJhziYFAg4n//SZ6YnR6N/dLRQKpVKpXC7nMhlBcP8B/OqX7I42JG4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0facd5300bf9ac6e45613b3250800c8c/e4706/2fa_Authentication_06.avif 230w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/d1af7/2fa_Authentication_06.avif 460w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/7f308/2fa_Authentication_06.avif 920w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/a4221/2fa_Authentication_06.avif 1128w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0facd5300bf9ac6e45613b3250800c8c/a0b58/2fa_Authentication_06.webp 230w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/bc10c/2fa_Authentication_06.webp 460w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/966d8/2fa_Authentication_06.webp 920w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/d9e4a/2fa_Authentication_06.webp 1128w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0facd5300bf9ac6e45613b3250800c8c/81c8e/2fa_Authentication_06.png 230w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/08a84/2fa_Authentication_06.png 460w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/c0255/2fa_Authentication_06.png 920w", "/en/static/0facd5300bf9ac6e45613b3250800c8c/7bf07/2fa_Authentication_06.png 1128w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0facd5300bf9ac6e45613b3250800c8c/c0255/2fa_Authentication_06.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "password-recovery",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#password-recovery",
        "aria-label": "password recovery permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Password Recovery`}</h2>
    <p>{`If you have forgotten your camera login you will now be able to click on the `}<strong parentName="p">{`Reset Password`}</strong>{` link in the camera login page:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb029cd7d7363113e7760192c724d8e5/c6671/2fa_Authentication_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAu0lEQVQY032QUW7DMAxDe/8DrBdZT7P9DcMGO4lrS7ElJZSLZMPQYkgf+CF9UBR46ncAUBFTNX2AmXPOAPojp/ulicRxENUVgPuPVuD1cnk5n3MpvXd3PzDXmr6+S4g1pd7an/Sa25Sk1oPk/Z4Q5bf39vHpIfY49Dh4iNs8jB6iMD97W+o8hyDTpOm6FloLgRjMzoxSjpN3VHWutRARE/FGE1EzYh5TaiLPzADMbDFbNmxZ7Je98/9t3wC7n5hlku0SEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb029cd7d7363113e7760192c724d8e5/e4706/2fa_Authentication_07.avif 230w", "/en/static/fb029cd7d7363113e7760192c724d8e5/d1af7/2fa_Authentication_07.avif 460w", "/en/static/fb029cd7d7363113e7760192c724d8e5/7f308/2fa_Authentication_07.avif 920w", "/en/static/fb029cd7d7363113e7760192c724d8e5/ebf6a/2fa_Authentication_07.avif 1129w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb029cd7d7363113e7760192c724d8e5/a0b58/2fa_Authentication_07.webp 230w", "/en/static/fb029cd7d7363113e7760192c724d8e5/bc10c/2fa_Authentication_07.webp 460w", "/en/static/fb029cd7d7363113e7760192c724d8e5/966d8/2fa_Authentication_07.webp 920w", "/en/static/fb029cd7d7363113e7760192c724d8e5/ea1d1/2fa_Authentication_07.webp 1129w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb029cd7d7363113e7760192c724d8e5/81c8e/2fa_Authentication_07.png 230w", "/en/static/fb029cd7d7363113e7760192c724d8e5/08a84/2fa_Authentication_07.png 460w", "/en/static/fb029cd7d7363113e7760192c724d8e5/c0255/2fa_Authentication_07.png 920w", "/en/static/fb029cd7d7363113e7760192c724d8e5/c6671/2fa_Authentication_07.png 1129w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb029cd7d7363113e7760192c724d8e5/c0255/2fa_Authentication_07.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You now have to provide the username of your account and the email you set as recovery / 2fa address:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a678a1189680981811b6ce1e0001801/76a99/2fa_Authentication_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAlklEQVQY04XQUQqDMAwGYO//5D12G2En8EHBwgpNav/GJGPKBpWJ31sCfxLSecvMVHVrmZn/051qIlqWRb5qre4+DEPf9+M4HtMvw8wcYyQiZs6ZiSjnvK7rPM/MfLM5pRRCIGYAv7NV1UyBchPOOwAiYjv9sE0NwE0YQPseU3dJwZ4PxOnoXIZFpJSCRkXJ5TVJPZ/9BlUbmyHunC2uAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a678a1189680981811b6ce1e0001801/e4706/2fa_Authentication_08.avif 230w", "/en/static/0a678a1189680981811b6ce1e0001801/d1af7/2fa_Authentication_08.avif 460w", "/en/static/0a678a1189680981811b6ce1e0001801/7f308/2fa_Authentication_08.avif 920w", "/en/static/0a678a1189680981811b6ce1e0001801/88d8d/2fa_Authentication_08.avif 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a678a1189680981811b6ce1e0001801/a0b58/2fa_Authentication_08.webp 230w", "/en/static/0a678a1189680981811b6ce1e0001801/bc10c/2fa_Authentication_08.webp 460w", "/en/static/0a678a1189680981811b6ce1e0001801/966d8/2fa_Authentication_08.webp 920w", "/en/static/0a678a1189680981811b6ce1e0001801/faa4b/2fa_Authentication_08.webp 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a678a1189680981811b6ce1e0001801/81c8e/2fa_Authentication_08.png 230w", "/en/static/0a678a1189680981811b6ce1e0001801/08a84/2fa_Authentication_08.png 460w", "/en/static/0a678a1189680981811b6ce1e0001801/c0255/2fa_Authentication_08.png 920w", "/en/static/0a678a1189680981811b6ce1e0001801/76a99/2fa_Authentication_08.png 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a678a1189680981811b6ce1e0001801/c0255/2fa_Authentication_08.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you set a security question you will now have to answer it before you can continue:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7cab3b3fb52ac665c5be2e09f195a972/62a6a/2fa_Authentication_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAnklEQVQY04WQTQqDQAyFe/9bFA/irttewEVBHK2odEbj/OXFgkIrHazfLpAvvLzLkiAi2CEiywGX/RBj7LrOOQeAmWOMALTWWZbleb7dPZRDCH3fj8YQ0bhCRNbaoijKskwj/Mpt26qqqpWqVf1smtEYAFsoZj6RXyvDMEzT9P0/em/nc/mzsWkM8aRxv/rHjf8XBiCkeBdmHRylbb8BLBKZjEugTX4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cab3b3fb52ac665c5be2e09f195a972/e4706/2fa_Authentication_09.avif 230w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/d1af7/2fa_Authentication_09.avif 460w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/7f308/2fa_Authentication_09.avif 920w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/9b5ba/2fa_Authentication_09.avif 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7cab3b3fb52ac665c5be2e09f195a972/a0b58/2fa_Authentication_09.webp 230w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/bc10c/2fa_Authentication_09.webp 460w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/966d8/2fa_Authentication_09.webp 920w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/e6b69/2fa_Authentication_09.webp 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cab3b3fb52ac665c5be2e09f195a972/81c8e/2fa_Authentication_09.png 230w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/08a84/2fa_Authentication_09.png 460w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/c0255/2fa_Authentication_09.png 920w", "/en/static/7cab3b3fb52ac665c5be2e09f195a972/62a6a/2fa_Authentication_09.png 1122w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7cab3b3fb52ac665c5be2e09f195a972/c0255/2fa_Authentication_09.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`At this stage you will be asked for a verification code that you will receive on your email address `}<strong parentName="p">{`Reset Password`}</strong>{` , e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SH5FkWyPXMcdwCxL9lAenIytj5oFoun`}</code>{`:`}</p>
    {/* __Passwort vergessen__
     ```bash
     Sehr geehrte(r) Kunde(in),
     um das Passwort Ihrer Kamera zurückzusetzen, verwenden Sie bitte den folgenden Code: SH5FkWyPXMcdwCxL9lAenIytj5oFoun
     Dieser ist für 1 Stunde gültig.
     Ihr INSTAR Team
     ``` */}
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Dear Customer,

to reset the password of your camera, please use the following code: SH5FkWyPXMcdwCxL9lAenIytj5oFoun

This code is valid `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hour.

Your INSTAR Team`}</code></pre></div>
    <p>{`Copy the code from your email and paste it into the input field as shown below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abffd53c831401d2b43d2a558a2df2d8/ae953/2fa_Authentication_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAm0lEQVQY04WPSw7CMAxEe/9drsCJ2CF1U0BdAE2bpPnhSRujVEJABOJpvLDksccNf2P9JD+pxpqqjzFKKQGkDQDM3LatEKLrOmZ+X1GbfQiXm9SzVWaTnq0PwzgeT2fr3J/LLsRh0tr6yVg1O2WcvwNrTsyRqEpem0FUnmQuyiVkyozrIe93NPWb9XfsZVmICHiplNdQfaJQxX4AwOJe6p+e4vYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abffd53c831401d2b43d2a558a2df2d8/e4706/2fa_Authentication_10.avif 230w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/d1af7/2fa_Authentication_10.avif 460w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/7f308/2fa_Authentication_10.avif 920w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/2c8e5/2fa_Authentication_10.avif 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abffd53c831401d2b43d2a558a2df2d8/a0b58/2fa_Authentication_10.webp 230w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/bc10c/2fa_Authentication_10.webp 460w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/966d8/2fa_Authentication_10.webp 920w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/cc785/2fa_Authentication_10.webp 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abffd53c831401d2b43d2a558a2df2d8/81c8e/2fa_Authentication_10.png 230w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/08a84/2fa_Authentication_10.png 460w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/c0255/2fa_Authentication_10.png 920w", "/en/static/abffd53c831401d2b43d2a558a2df2d8/ae953/2fa_Authentication_10.png 1132w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abffd53c831401d2b43d2a558a2df2d8/c0255/2fa_Authentication_10.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once the verification code was accepted you can now set a new login password for your camera account:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/979ed97bd1625fc5171bc4da793cfd2e/25260/2fa_Authentication_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAqUlEQVQY04WQzQrCMBCE+/j1aXwJod69eBChR1u0TUz2J7tJhIZiK2o/5rILs8NslReklHgirAEAa20IIa+plgMz933vAVRVZnLOTXOs613btiXgl5nMODjnnHcFVS1HjTGI8C+ZiPvH8ASkoCxxkrJoUBWNSLRhvhvnkCEoSZyVKGVMyRNvdF5WKk+UmOPtlM97tl3ZfDeLCAAgIr0FgITdBa8H9uNH8gsV35l8VO+tWwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/979ed97bd1625fc5171bc4da793cfd2e/e4706/2fa_Authentication_11.avif 230w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/d1af7/2fa_Authentication_11.avif 460w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/7f308/2fa_Authentication_11.avif 920w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/e3393/2fa_Authentication_11.avif 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/979ed97bd1625fc5171bc4da793cfd2e/a0b58/2fa_Authentication_11.webp 230w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/bc10c/2fa_Authentication_11.webp 460w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/966d8/2fa_Authentication_11.webp 920w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/9bbd4/2fa_Authentication_11.webp 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/979ed97bd1625fc5171bc4da793cfd2e/81c8e/2fa_Authentication_11.png 230w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/08a84/2fa_Authentication_11.png 460w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/c0255/2fa_Authentication_11.png 920w", "/en/static/979ed97bd1625fc5171bc4da793cfd2e/25260/2fa_Authentication_11.png 1113w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/979ed97bd1625fc5171bc4da793cfd2e/c0255/2fa_Authentication_11.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "2fa-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2fa-login",
        "aria-label": "2fa login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2FA Login`}</h2>
    <p>{`With the 2-Factor Authentication active you will now be asked to provide a security code when logging in. After the successful login you will be asked to provide a 6-digits access code that will be send to you by email `}<strong parentName="p">{`2FA-Access Code`}</strong>{`, e.g. bdEAl5:`}</p>
    {/* __2FA-Zugangscode__
     ```bash
     Sehr geehrte(r) Kunde(in),
     der Zugangscode zu Ihrer Kamera lautet: bdEAl5
     Dieser ist für 1 Stunde gültig.
     Ihr INSTAR Team
     ``` */}
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Dear Customer,

the access code to your camera is: bdEAl5

This code is valid `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` hour.

Your INSTAR Team`}</code></pre></div>
    <p>{`Copy&paste in the access code to load the camera user interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/188facc957542e5230c58e6d07c5664a/a0730/2fa_Authentication_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAlElEQVQY06WRwQoCMQxE9/+/ZL335FHwN0SFXpZulcVdm5mklbInV6GIwxAmJA8C6cof6ja9fsjMhmHw3uecG3CMMYRAQkRSSiJC0jm363uSDXiapjCO87zMS/VakkBVG3DOmaSqASSoNauV8tAiaiQaMPC2oUS5Hstpb+fDZvTlbAAi1atSeuLuZbzg5s2sAf/0qheL0tbw0ehvrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/188facc957542e5230c58e6d07c5664a/e4706/2fa_Authentication_12.avif 230w", "/en/static/188facc957542e5230c58e6d07c5664a/d1af7/2fa_Authentication_12.avif 460w", "/en/static/188facc957542e5230c58e6d07c5664a/7f308/2fa_Authentication_12.avif 920w", "/en/static/188facc957542e5230c58e6d07c5664a/b1c5f/2fa_Authentication_12.avif 1131w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/188facc957542e5230c58e6d07c5664a/a0b58/2fa_Authentication_12.webp 230w", "/en/static/188facc957542e5230c58e6d07c5664a/bc10c/2fa_Authentication_12.webp 460w", "/en/static/188facc957542e5230c58e6d07c5664a/966d8/2fa_Authentication_12.webp 920w", "/en/static/188facc957542e5230c58e6d07c5664a/bcfd3/2fa_Authentication_12.webp 1131w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/188facc957542e5230c58e6d07c5664a/81c8e/2fa_Authentication_12.png 230w", "/en/static/188facc957542e5230c58e6d07c5664a/08a84/2fa_Authentication_12.png 460w", "/en/static/188facc957542e5230c58e6d07c5664a/c0255/2fa_Authentication_12.png 920w", "/en/static/188facc957542e5230c58e6d07c5664a/a0730/2fa_Authentication_12.png 1131w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/188facc957542e5230c58e6d07c5664a/c0255/2fa_Authentication_12.png",
              "alt": "Camera Login with 2-Factor Authentication",
              "title": "Camera Login with 2-Factor Authentication",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      